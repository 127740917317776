import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  // Initialize authentication context
  const storedAuth = localStorage.getItem("auth");
  const initialAuth = storedAuth ? JSON.parse(storedAuth) : null;
  const [auth, setAuth] = useState(initialAuth);

  const navigate = useNavigate();
  const logoutUser = async () => {
    // axios to /logout endpoint
    setAuth({});
    navigate("/");
  };

  return (
    <AuthContext.Provider value={{ auth, setAuth, logoutUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
