import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";

const RequireAuth = lazy(() => import("./components/RequireAuth"));
const PersistLogin = lazy(() => import("./components/PersistLogin"));
const Layout = lazy(() => import("./components/Layout"));
const Main = lazy(() => import("./pages/Home.js"));
const AboutUs = lazy(() => import("./pages/AboutUs.js"));
const Services = lazy(() => import("./pages/Services.js"));
const Development = lazy(() => import("./pages/Development"));
const WebDesign = lazy(() => import("./pages/WebDesign"));
const Design = lazy(() => import("./pages/Design"));
const Consulting = lazy(() => import("./pages/Consulting"));
const Marketing = lazy(() => import("./pages/Marketing"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const BlogsPage = lazy(() => import("./pages/BlogsPage"));
const BlogPost = lazy(() => import("./components/Blog"));
const ContactUs = lazy(() => import("./pages/ContactUs"));
const MotionGraphics = lazy(() => import("./pages/MotionGraphics"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Questionnaire = lazy(() => import("./components/Questionnaire.js"));
const Login = lazy(() => import("./clientportal/Login"));
const NotFound = lazy(() => import("./pages/NotFound.js"));
const AdminPortal = lazy(() => import("./clientportal/admin/AdminPortal.js"));
const ClientPortal = lazy(() =>
  import("./clientportal/client/ClientPortal.js")
);

const ROLES = {
  Admin: "admin",
  Client: "client",
};

function App() {
  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" exact element={<Layout />}>
            {/* Public routes */}
            <Route path="/" exact element={<Main />} />
            <Route path="/about-us" exact element={<AboutUs />} />
            <Route path="/services" exact element={<Services />} />
            <Route path="/app-development" exact element={<Development />} />
            <Route path="/web-development" exact element={<WebDesign />} />
            <Route path="/web-design" exact element={<WebDesign />} />
            <Route path="/consulting" exact element={<Consulting />} />
            <Route path="/design" exact element={<Design />} />
            <Route path="/marketing" exact element={<Marketing />} />
            <Route path="/motion-graphics" exact element={<MotionGraphics />} />
            <Route path="/portfolio" exact element={<Portfolio />} />
            <Route path="/blog" exact element={<BlogsPage />} />
            <Route path="/blog/:id" exact element={<BlogPost />} />
            <Route path="/contact-us" exact element={<ContactUs />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/questionnaire" exact element={<Questionnaire />} />

            {/* Authentication routes */}
            <Route exact path="/login" element={<Login />} />

            <Route element={<PersistLogin />}>
              {/* Private routes */}
              <Route element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
                <Route path="/admin" element={<AdminPortal />} />
              </Route>

              <Route element={<RequireAuth allowedRoles={[ROLES.Client]} />}>
                <Route path="/client" element={<ClientPortal />} />
              </Route>
            </Route>

            {/* 404 routes */}
            <Route path="/404" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
